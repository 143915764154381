import React from "react";
import Layout from "../components/layout/index";
import Seo from "../components/seo";
import BioLink from "../components/BioLink";

const Index = () => {
  return (
    <Layout>
      <Seo
        slug="/bio-link/"
        title="Best website for Bio | Link in Bio Free - Teenyfy"
        description="Find the Best website for bio links to all your requirements. Bundle up all your URLs in one place. Free and Easy Setup. All Socials, One Link management."
      />
      <BioLink />
    </Layout>
  );
};

export default Index;
