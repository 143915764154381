import React from "react";

const TowCol = ({ Image, title, description, order }) => {
  return (
    <div
      className={`container mx-auto flex md:items-start items-center lg:gap-[120px] gap-8 lg:px-40 ${
        order === "img"
          ? "lg:flex-row-reverse flex-col "
          : " lg:flex-row flex-col"
      }`}
    >
      <div className="flex-1 text-black font-roboto flex items-start justify-center flex-col">
        <p className="text-[40px] leading-8 ">{title}</p>
        <p className="text-base mt-6 lg:max-w-[85%]">{description}</p>
      </div>
      <div className="">
        <img src={Image} alt="banner-image" />
      </div>
    </div>
  );
};

export default TowCol;
