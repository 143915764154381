import React from "react";
import BannerImage from "../../images/bio-link/illustration.png";

const Banner = () => {
  return (
    <div className="w-full flex lg:flex-row flex-col-reverse justify-between items-center lg:px-[75px] md:gap-[98px] gap-6">
      <div className="flex-1 text-black font-roboto md:mb-0 mb-9">
        <p className="lg;text-5xl text-[40px]">
          One Bio Link, Infinite Possibilities
        </p>
        <p className="lg:text-2xl text-xl mt-6 lg:max-w-[85%]">
          Consolidate all your links into a single, easy-to-use landing page
          with <span className="text-dark-green">Teenyfy</span> Bio Link.
        </p>
        <button className="bg-dark-green py-5 px-14 mt-10 rounded-full text-white font-bold text-xl">
          Get Started
        </button>
      </div>
      <div className="">
        <img src={BannerImage} alt="banner-image" />
      </div>
    </div>
  );
};

export default Banner;
