import React from "react";
import Banner from "./banner";
import Banner1Image from "../../images/bio-link/Banner1.png";
import Schedule from "../../images/bio-link/Schedule.png";
import Social from "../../images/bio-link/Social.png";
import Theme from "../../images/bio-link/Theme.png";
import TowCol from "./TowCol";
import ContactUs from "../homepage/contactUs/contactUs";

const BioLink = () => {
  return (
    <>
      <div className="container px-5 pt-8 py-0 md:py-24 mx-auto">
        <Banner />
      </div>

      <div className="bg-aliceGreen pt-[106px] flex  flex-col gap-12  lg:px-0 md:px-6 px-5">
        <TowCol
          Image={Banner1Image}
          title="Multiple Links"
          description="Unlock the Power of Multiple Links in Your Bio! Tired of limited space in your social media bio? With our service, you can add multiple links to your bio, directing your audience to all your important content."
          order="img"
        />
        <TowCol
          Image={Schedule}
          title="Scheduling"
          description="Take Control of Your Bio Links! With our scheduling feature, you can plan and schedule when your bio links go live. Whether you're promoting a sale, an event, or a new blog post, you can schedule your links in advance and focus on creating great content."
          order="text"
        />
        <TowCol
          Image={Social}
          title="Social links"
          description="Expand Your Reach with Social Links in Your Bio! With our service, you can add social media links to your bio, directing your audience to all your profiles in one place. Increase your followers, connect with your audience, and grow your online presence effortlessly."
          order="img"
        />
        <TowCol
          Image={Theme}
          title="Themes"
          description="Transform Your Bio Link with Stunning Themes! Elevate your online presence with our collection of beautifully designed themes for your bio link. Customize your page to match your brand and captivate your audience."
          order="text"
        />
      </div>
      <ContactUs marginValue={true} />
    </>
  );
};

export default BioLink;
